import type * as Types from '~/api/schema.graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type LocationLookupQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type LocationLookupQuery = { readonly search: (
    { readonly __typename?: 'SearchOutput' }
    & { readonly items: ReadonlyArray<{ readonly __typename?: 'DerivativeScriptureProduct' } | { readonly __typename?: 'DirectScriptureProduct' } | { readonly __typename?: 'EthnoArt' } | { readonly __typename?: 'FieldRegion' } | { readonly __typename?: 'FieldZone' } | { readonly __typename?: 'Film' } | { readonly __typename?: 'FinancialReport' } | { readonly __typename?: 'FundingAccount' } | { readonly __typename?: 'InternshipProject' } | { readonly __typename?: 'Language' } | (
      { readonly __typename?: 'Location' }
      & Pick<Types.Location, 'id'>
      & { readonly name: (
        { readonly __typename?: 'SecuredString' }
        & Pick<Types.SecuredString, 'canRead' | 'canEdit' | 'value'>
      ) }
    ) | { readonly __typename?: 'MomentumTranslationProject' } | { readonly __typename?: 'MultiplicationTranslationProject' } | { readonly __typename?: 'NarrativeReport' } | { readonly __typename?: 'Organization' } | { readonly __typename?: 'OtherProduct' } | { readonly __typename?: 'Partner' } | { readonly __typename?: 'ProgressReport' } | { readonly __typename?: 'Story' } | { readonly __typename?: 'User' }> }
  ) };


export const LocationLookupDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"LocationLookup"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"search"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"type"},"value":{"kind":"ListValue","values":[{"kind":"EnumValue","value":"Location"}]}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"items"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"DisplayLocation"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"DisplayLocation"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Location"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"canRead"}},{"kind":"Field","name":{"kind":"Name","value":"canEdit"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]} as unknown as DocumentNode<LocationLookupQuery, LocationLookupQueryVariables>;
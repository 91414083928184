import type * as Types from '../schema.graphql';


export const CeremonyTypeList: readonly Types.CeremonyType[] = [
  'Certification',
  'Dedication',
];

export const CeremonyTypeLabels: Readonly<Record<Types.CeremonyType, string>> = {
  Certification: `Certification`,
  Dedication: `Dedication`,
};

export const DegreeList: readonly Types.Degree[] = [
  'Associates',
  'Bachelors',
  'Doctorate',
  'Masters',
  'Primary',
  'Secondary',
];

export const DegreeLabels: Readonly<Record<Types.Degree, string>> = {
  Associates: `Associates`,
  Bachelors: `Bachelors`,
  Doctorate: `Doctorate`,
  Masters: `Masters`,
  Primary: `Primary`,
  Secondary: `Secondary`,
};

export const EngagementStatusList: readonly Types.EngagementStatus[] = [
  'InDevelopment',
  'DidNotDevelop',
  'Rejected',
  'Active',
  'ActiveChangedPlan',
  'DiscussingTermination',
  'DiscussingReactivation',
  'DiscussingChangeToPlan',
  'DiscussingSuspension',
  'Suspended',
  'FinalizingCompletion',
  'Terminated',
  'Completed',
];

export const EngagementStatusLabels: Readonly<Record<Types.EngagementStatus, string>> = {
  Active: `Active`,
  ActiveChangedPlan: `Active Changed Plan`,
  Completed: `Completed`,
  Converted: `Converted`,
  DidNotDevelop: `Did Not Develop`,
  DiscussingChangeToPlan: `Discussing Change to Plan`,
  DiscussingReactivation: `Discussing Reactivation`,
  DiscussingSuspension: `Discussing Suspension`,
  DiscussingTermination: `Discussing Termination`,
  FinalizingCompletion: `Finalizing Completion`,
  InDevelopment: `In Development`,
  NotRenewed: `Not Renewed`,
  Rejected: `Rejected`,
  Suspended: `Suspended`,
  Terminated: `Terminated`,
  Transferred: `Transferred`,
  Unapproved: `Unapproved`,
};

export const EngagementTransitionTypeList: readonly Types.EngagementTransitionType[] = [
  'Approve',
  'Neutral',
  'Reject',
];

export const EngagementTransitionTypeLabels: Readonly<Record<Types.EngagementTransitionType, string>> = {
  Approve: `Approve`,
  Neutral: `Neutral`,
  Reject: `Reject`,
};

export const FileNodeTypeList: readonly Types.FileNodeType[] = [
  'Directory',
  'File',
  'FileVersion',
];

export const FileNodeTypeLabels: Readonly<Record<Types.FileNodeType, string>> = {
  Directory: `Directory`,
  File: `File`,
  FileVersion: `File Version`,
};

export const FinancialReportingTypeList: readonly Types.FinancialReportingType[] = [
  'FieldEngaged',
  'Funded',
  'Hybrid',
];

export const FinancialReportingTypeLabels: Readonly<Record<Types.FinancialReportingType, string>> = {
  FieldEngaged: `Field Engaged`,
  Funded: `Funded`,
  Hybrid: `Hybrid`,
};

export const InternshipDomainList: readonly Types.InternshipDomain[] = [
  'FieldPrograms',
  'Leadership',
  'Operations',
];

export const InternshipDomainLabels: Readonly<Record<Types.InternshipDomain, string>> = {
  FieldPrograms: `Field Programs`,
  Leadership: `Leadership`,
  Operations: `Operations`,
};

export const InternshipPositionList: readonly Types.InternshipPosition[] = [
  'Administration',
  'Communication',
  'ConsultantInTraining',
  'Finance',
  'LanguageProgramManager',
  'LeadershipDevelopment',
  'Literacy',
  'MidLevelQualityAssurance',
  'Mobilization',
  'OralityFacilitator',
  'Personnel',
  'ScriptureEngagement',
  'Technology',
];

export const InternshipPositionLabels: Readonly<Record<Types.InternshipPosition, string>> = {
  Administration: `Administration`,
  Communication: `Communication`,
  ConsultantInTraining: `Consultant in Training`,
  ExegeticalFacilitator: `Exegetical Facilitator`,
  Finance: `Finance`,
  LanguageProgramManager: `Language Program Manager`,
  LeadershipDevelopment: `Leadership Development`,
  Literacy: `Literacy`,
  MidLevelQualityAssurance: `Mid Level Quality Assurance`,
  Mobilization: `Mobilization`,
  OralityFacilitator: `Orality Facilitator`,
  OtherAttached: `Other Attached`,
  OtherPartnershipCapacity: `Other Partnership Capacity`,
  OtherTranslationCapacity: `Other Translation Capacity`,
  Personnel: `Personnel`,
  ScriptureEngagement: `Scripture Engagement`,
  Technology: `Technology`,
  TranslationFacilitator: `Translation Facilitator`,
};

export const InternshipProgramList: readonly Types.InternshipProgram[] = [
  'CapacityBuilding',
  'QualityAssurance',
];

export const InternshipProgramLabels: Readonly<Record<Types.InternshipProgram, string>> = {
  CapacityBuilding: `Capacity Building`,
  QualityAssurance: `Quality Assurance`,
};

export const LanguageMilestoneList: readonly Types.LanguageMilestone[] = [
  'Unknown',
  'None',
  'OldTestament',
  'NewTestament',
  'FullBible',
];

export const LanguageMilestoneLabels: Readonly<Record<Types.LanguageMilestone, string>> = {
  FullBible: `Full Bible`,
  NewTestament: `New Testament`,
  None: `None`,
  OldTestament: `Old Testament`,
  Unknown: `Unknown`,
};

export const LanguageProficiencyList: readonly Types.LanguageProficiency[] = [
  'Beginner',
  'Conversational',
  'Fluent',
  'Skilled',
];

export const LanguageProficiencyLabels: Readonly<Record<Types.LanguageProficiency, string>> = {
  Beginner: `Beginner`,
  Conversational: `Conversational`,
  Fluent: `Fluent`,
  Skilled: `Skilled`,
};

export const LocationTypeList: readonly Types.LocationType[] = [
  'City',
  'Country',
  'County',
  'CrossBorderArea',
  'Region',
  'State',
];

export const LocationTypeLabels: Readonly<Record<Types.LocationType, string>> = {
  City: `City`,
  Country: `Country`,
  County: `County`,
  CrossBorderArea: `Cross-Border Area`,
  Region: `Region`,
  State: `State`,
};

export const OrderList: readonly Types.Order[] = [
  'ASC',
  'DESC',
];

export const OrderLabels: Readonly<Record<Types.Order, string>> = {
  ASC: `Asc`,
  DESC: `Desc`,
};

export const OrganizationReachList: readonly Types.OrganizationReach[] = [
  'Global',
  'Local',
  'National',
  'Regional',
];

export const OrganizationReachLabels: Readonly<Record<Types.OrganizationReach, string>> = {
  Global: `Global`,
  Local: `Local`,
  National: `National`,
  Regional: `Regional`,
};

export const OrganizationTypeList: readonly Types.OrganizationType[] = [
  'Alliance',
  'Church',
  'Mission',
  'Parachurch',
  'TranslationOrganization',
];

export const OrganizationTypeLabels: Readonly<Record<Types.OrganizationType, string>> = {
  Alliance: `Alliance`,
  Church: `Church`,
  Mission: `Mission`,
  Parachurch: `Parachurch`,
  TranslationOrganization: `Translation Organization`,
};

export const PartnerTypeList: readonly Types.PartnerType[] = [
  'Funding',
  'Impact',
  'Managing',
  'Resource',
  'Technical',
];

export const PartnerTypeLabels: Readonly<Record<Types.PartnerType, string>> = {
  Funding: `Funding`,
  Impact: `Impact`,
  Managing: `Managing`,
  Resource: `Resource`,
  Technical: `Technical`,
};

export const PartnershipAgreementStatusList: readonly Types.PartnershipAgreementStatus[] = [
  'AwaitingSignature',
  'NotAttached',
  'Signed',
];

export const PartnershipAgreementStatusLabels: Readonly<Record<Types.PartnershipAgreementStatus, string>> = {
  AwaitingSignature: `Awaiting Signature`,
  NotAttached: `Not Attached`,
  Signed: `Signed`,
};

export const PeriodTypeList: readonly Types.PeriodType[] = [
  'Monthly',
  'Quarterly',
];

export const PeriodTypeLabels: Readonly<Record<Types.PeriodType, string>> = {
  Monthly: `Monthly`,
  Quarterly: `Quarterly`,
};

export const PnpProblemSeverityList: readonly Types.PnpProblemSeverity[] = [
  'Error',
  'Notice',
  'Warning',
];

export const PnpProblemSeverityLabels: Readonly<Record<Types.PnpProblemSeverity, string>> = {
  Error: `Error`,
  Notice: `Notice`,
  Warning: `Warning`,
};

export const PostShareabilityList: readonly Types.PostShareability[] = [
  'AskToShareExternally',
  'External',
  'Internal',
  'Membership',
];

export const PostShareabilityLabels: Readonly<Record<Types.PostShareability, string>> = {
  AskToShareExternally: `Ask to Share Externally`,
  External: `External`,
  Internal: `Internal`,
  Membership: `Team Members`,
  ProjectTeam: `Team Members`,
};

export const PostTypeList: readonly Types.PostType[] = [
  'Note',
  'Prayer',
  'Story',
];

export const PostTypeLabels: Readonly<Record<Types.PostType, string>> = {
  Note: `Note`,
  Prayer: `Prayer`,
  Story: `Story`,
};

export const PowerList: readonly Types.Power[] = [
  'BetaFeatures',
  'CreateEthnoArt',
  'CreateFieldRegion',
  'CreateFieldZone',
  'CreateFilm',
  'CreateFundingAccount',
  'CreateLanguage',
  'CreateLiteracyMaterial',
  'CreateLocation',
  'CreateOrganization',
  'CreatePartner',
  'CreateProject',
  'CreateSong',
  'CreateStory',
  'CreateUser',
];

export const PowerLabels: Readonly<Record<Types.Power, string>> = {
  BetaFeatures: `Beta Features`,
  CreateBudget: `Create Budget`,
  CreateBudgetRecord: `Create Budget Record`,
  CreateCeremony: `Create Ceremony`,
  CreateChangeRequest: `Create Change Request`,
  CreateDirectory: `Create Directory`,
  CreateEducation: `Create Education`,
  CreateEthnoArt: `Create Ethno Art`,
  CreateEthnologueLanguage: `Create Ethnologue Language`,
  CreateFieldRegion: `Create Field Region`,
  CreateFieldZone: `Create Field Zone`,
  CreateFile: `Create File`,
  CreateFileVersion: `Create File Version`,
  CreateFilm: `Create Film`,
  CreateFundingAccount: `Create Funding Account`,
  CreateInternshipEngagement: `Create Internship Engagement`,
  CreateLanguage: `Create Language`,
  CreateLanguageEngagement: `Create Language Engagement`,
  CreateLiteracyMaterial: `Create Literacy Material`,
  CreateLocation: `Create Location`,
  CreateOrganization: `Create Organization`,
  CreatePartner: `Create Partner`,
  CreatePartnership: `Create Partnership`,
  CreatePost: `Create Post`,
  CreateProduct: `Create Product`,
  CreateProject: `Create Project`,
  CreateProjectEngagement: `Create Project Engagement`,
  CreateProjectMember: `Create Project Member`,
  CreateSong: `Create Song`,
  CreateStory: `Create Story`,
  CreateTranslationEngagement: `Create Translation Engagement`,
  CreateUnavailability: `Create Unavailability`,
  CreateUser: `Create User`,
  GrantInternRole: `Grant Intern Role`,
  GrantLiaisonRole: `Grant Liaison Role`,
  GrantMentorRole: `Grant Mentor Role`,
  GrantRegionalCommunicationsCoordinatorRole: `Grant Regional Communications Coordinator Role`,
  GrantTranslatorRole: `Grant Translator Role`,
};

export const ProducibleTypeList: readonly Types.ProducibleType[] = [
  'DerivativeScriptureProduct',
  'DirectScriptureProduct',
  'EthnoArt',
  'Film',
  'OtherProduct',
  'Story',
];

export const ProducibleTypeLabels: Readonly<Record<Types.ProducibleType, string>> = {
  DerivativeScriptureProduct: `Derivative Scripture Product`,
  DirectScriptureProduct: `Direct Scripture Product`,
  EthnoArt: `Ethno Art`,
  Film: `Film`,
  OtherProduct: `Other Product`,
  Story: `Story`,
};

export const ProductApproachList: readonly Types.ProductApproach[] = [
  'OralStories',
  'OralTranslation',
  'Visual',
  'Written',
];

export const ProductApproachLabels: Readonly<Record<Types.ProductApproach, string>> = {
  OralStories: `Oral Stories`,
  OralTranslation: `Oral Translation`,
  Visual: `Visual`,
  Written: `Written`,
};

export const ProductMediumList: readonly Types.ProductMedium[] = [
  'App',
  'Audio',
  'EBook',
  'Other',
  'Print',
  'TrainedStoryTellers',
  'Video',
  'Web',
];

export const ProductMediumLabels: Readonly<Record<Types.ProductMedium, string>> = {
  App: `App`,
  Audio: `Audio`,
  EBook: `E-Book`,
  Other: `Other`,
  Print: `Print`,
  TrainedStoryTellers: `Trained Story Tellers`,
  Video: `Video`,
  Web: `Web`,
};

export const ProductMethodologyList: readonly Types.ProductMethodology[] = [
  'AdobeAudition',
  'Audacity',
  'Craft2Tell',
  'Film',
  'OneStory',
  'OtherOralStories',
  'OtherOralTranslation',
  'OtherVisual',
  'OtherWritten',
  'Paratext',
  'Render',
  'SeedCompanyMethod',
  'SignLanguage',
  'StoryTogether',
];

export const ProductMethodologyLabels: Readonly<Record<Types.ProductMethodology, string>> = {
  AdobeAudition: `Adobe Audition`,
  Audacity: `Audacity`,
  Craft2Tell: `Craft 2 Tell`,
  Film: `Film`,
  OneStory: `One Story`,
  OtherOralStories: `Other Oral Stories`,
  OtherOralTranslation: `Other Oral Translation`,
  OtherVisual: `Other Visual`,
  OtherWritten: `Other Written`,
  Paratext: `Paratext`,
  Render: `Render`,
  SeedCompanyMethod: `Seed Company Method`,
  SignLanguage: `Sign Language`,
  StoryTogether: `Story Together`,
};

export const ProductPurposeList: readonly Types.ProductPurpose[] = [
  'ChurchLife',
  'ChurchMaturity',
  'Discipleship',
  'EvangelismChurchPlanting',
  'SocialIssues',
];

export const ProductPurposeLabels: Readonly<Record<Types.ProductPurpose, string>> = {
  ChurchLife: `Church Life`,
  ChurchMaturity: `Church Maturity`,
  Discipleship: `Discipleship`,
  EvangelismChurchPlanting: `Evangelism Church Planting`,
  SocialIssues: `Social Issues`,
};

export const ProductStepList: readonly Types.ProductStep[] = [
  'BackTranslation',
  'Check',
  'CommunityTesting',
  'Completed',
  'ConsistencyCheckAndFinalEdits',
  'ConsultantCheck',
  'Craft',
  'Develop',
  'ExegesisAndFirstDraft',
  'InternalizationAndDrafting',
  'PeerRevision',
  'Record',
  'TeamCheck',
  'Test',
  'Translate',
];

export const ProductStepLabels: Readonly<Record<Types.ProductStep, string>> = {
  BackTranslation: `Back Translation`,
  Check: `Check`,
  CommunityTesting: `Community Testing`,
  Completed: `Completed`,
  ConsistencyCheckAndFinalEdits: `Consistency Check & Final Edits`,
  ConsultantCheck: `Consultant Check`,
  Craft: `Craft`,
  Develop: `Develop`,
  ExegesisAndFirstDraft: `Exegesis & First Draft`,
  InternalizationAndDrafting: `Internalization & Drafting`,
  PeerRevision: `Peer Revision`,
  Record: `Record`,
  TeamCheck: `Team Check`,
  Test: `Test`,
  Translate: `Translate`,
};

export const ProgressFormatList: readonly Types.ProgressFormat[] = [
  'Decimal',
  'Numerator',
  'Percent',
  'VerseEquivalents',
  'Verses',
];

export const ProgressFormatLabels: Readonly<Record<Types.ProgressFormat, string>> = {
  Decimal: `Decimal`,
  Numerator: `Numerator`,
  Percent: `Percent`,
  VerseEquivalents: `Verse Equivalents`,
  Verses: `Verses`,
};

export const ProgressMeasurementList: readonly Types.ProgressMeasurement[] = [
  'Boolean',
  'Number',
  'Percent',
];

export const ProgressMeasurementLabels: Readonly<Record<Types.ProgressMeasurement, string>> = {
  Boolean: `Done / Not Done`,
  Number: `Number`,
  Percent: `Percent`,
};

export const ProgressReportMediaCategoryList: readonly Types.ProgressReportMediaCategory[] = [
  'CommunityEngagement',
  'Events',
  'LifeInCommunity',
  'Other',
  'SceneryLandscape',
  'Team',
  'WorkInProgress',
];

export const ProgressReportMediaCategoryLabels: Readonly<Record<Types.ProgressReportMediaCategory, string>> = {
  CommunityEngagement: `Community Engagement with the word`,
  Events: `Events`,
  LifeInCommunity: `Life in Community`,
  Other: `Other`,
  SceneryLandscape: `Scenery & Landscape`,
  Team: `Team`,
  WorkInProgress: `Work in Progress`,
};

export const ProgressReportStatusList: readonly Types.ProgressReportStatus[] = [
  'NotStarted',
  'InProgress',
  'PendingTranslation',
  'InReview',
  'Approved',
  'Published',
];

export const ProgressReportStatusLabels: Readonly<Record<Types.ProgressReportStatus, string>> = {
  Approved: `Approved`,
  InProgress: `In Progress`,
  InReview: `In Review`,
  NotStarted: `Not Started`,
  PendingTranslation: `Pending Translation`,
  Published: `Published`,
};

export const ProjectChangeRequestStatusList: readonly Types.ProjectChangeRequestStatus[] = [
  'Approved',
  'Pending',
  'Rejected',
];

export const ProjectChangeRequestStatusLabels: Readonly<Record<Types.ProjectChangeRequestStatus, string>> = {
  Approved: `Approved`,
  Pending: `Pending`,
  Rejected: `Rejected`,
};

export const ProjectChangeRequestTypeList: readonly Types.ProjectChangeRequestType[] = [
  'Budget',
  'Engagement',
  'Goal',
  'Other',
  'Time',
];

export const ProjectChangeRequestTypeLabels: Readonly<Record<Types.ProjectChangeRequestType, string>> = {
  Budget: `Budget`,
  Engagement: `Engagement`,
  Goal: `Goal`,
  Other: `Other`,
  Time: `Time`,
};

export const ProjectStatusList: readonly Types.ProjectStatus[] = [
  'InDevelopment',
  'Active',
  'Terminated',
  'Completed',
  'DidNotDevelop',
];

export const ProjectStatusLabels: Readonly<Record<Types.ProjectStatus, string>> = {
  Active: `Active`,
  Completed: `Completed`,
  DidNotDevelop: `Did Not Develop`,
  InDevelopment: `In Development`,
  Terminated: `Terminated`,
};

export const ProjectStepList: readonly Types.ProjectStep[] = [
  'EarlyConversations',
  'PendingConceptApproval',
  'PrepForConsultantEndorsement',
  'PendingConsultantEndorsement',
  'PrepForFinancialEndorsement',
  'PendingFinancialEndorsement',
  'FinalizingProposal',
  'PendingRegionalDirectorApproval',
  'PendingZoneDirectorApproval',
  'PendingFinanceConfirmation',
  'OnHoldFinanceConfirmation',
  'DidNotDevelop',
  'Rejected',
  'Active',
  'ActiveChangedPlan',
  'DiscussingChangeToPlan',
  'PendingChangeToPlanApproval',
  'PendingChangeToPlanConfirmation',
  'DiscussingSuspension',
  'PendingSuspensionApproval',
  'Suspended',
  'DiscussingReactivation',
  'PendingReactivationApproval',
  'DiscussingTermination',
  'PendingTerminationApproval',
  'FinalizingCompletion',
  'Terminated',
  'Completed',
];

export const ProjectStepLabels: Readonly<Record<Types.ProjectStep, string>> = {
  Active: `Active`,
  ActiveChangedPlan: `Active Changed Plan`,
  Completed: `Completed`,
  DidNotDevelop: `Did Not Develop`,
  DiscussingChangeToPlan: `Discussing Change to Plan`,
  DiscussingReactivation: `Discussing Reactivation`,
  DiscussingSuspension: `Discussing Suspension`,
  DiscussingTermination: `Discussing Termination`,
  EarlyConversations: `Early Conversations`,
  FinalizingCompletion: `Finalizing Completion`,
  FinalizingProposal: `Finalizing Proposal`,
  OnHoldFinanceConfirmation: `On Hold Finance Confirmation`,
  PendingChangeToPlanApproval: `Pending Change to Plan Approval`,
  PendingChangeToPlanConfirmation: `Pending Change to Plan Confirmation`,
  PendingConceptApproval: `Pending Concept Approval`,
  PendingConsultantEndorsement: `Pending Consultant Endorsement`,
  PendingFinanceConfirmation: `Pending Finance Confirmation`,
  PendingFinancialEndorsement: `Pending Financial Endorsement`,
  PendingReactivationApproval: `Pending Reactivation Approval`,
  PendingRegionalDirectorApproval: `Pending Regional Director Approval`,
  PendingSuspensionApproval: `Pending Suspension Approval`,
  PendingTerminationApproval: `Pending Termination Approval`,
  PendingZoneDirectorApproval: `Pending Field Operations Approval`,
  PrepForConsultantEndorsement: `Prep for Consultant Endorsement`,
  PrepForFinancialEndorsement: `Prep for Financial Endorsement`,
  Rejected: `Rejected`,
  Suspended: `Suspended`,
  Terminated: `Terminated`,
};

export const ProjectTypeList: readonly Types.ProjectType[] = [
  'MomentumTranslation',
  'MultiplicationTranslation',
  'Internship',
];

export const ProjectTypeLabels: Readonly<Record<Types.ProjectType, string>> = {
  Internship: `Internship`,
  MomentumTranslation: `Momentum`,
  MultiplicationTranslation: `Multiplication`,
};

export const ReportTypeList: readonly Types.ReportType[] = [
  'Financial',
  'Narrative',
  'Progress',
];

export const ReportTypeLabels: Readonly<Record<Types.ReportType, string>> = {
  Financial: `Financial`,
  Narrative: `Narrative`,
  Progress: `Progress`,
};

export const RoleList: readonly Types.Role[] = [
  'Administrator',
  'BetaTester',
  'BibleTranslationLiaison',
  'Consultant',
  'ConsultantManager',
  'Controller',
  'ExperienceOperations',
  'FieldOperationsDirector',
  'FieldPartner',
  'FinancialAnalyst',
  'Fundraising',
  'Intern',
  'LeadFinancialAnalyst',
  'Leadership',
  'Liaison',
  'Marketing',
  'Mentor',
  'ProjectManager',
  'RegionalCommunicationsCoordinator',
  'RegionalDirector',
  'StaffMember',
  'Translator',
];

export const RoleLabels: Readonly<Record<Types.Role, string>> = {
  Administrator: `Administrator`,
  BetaTester: `Beta Tester`,
  BibleTranslationLiaison: `Bible Translation Liaison`,
  Consultant: `Consultant`,
  ConsultantManager: `Consultant Manager`,
  Controller: `Controller`,
  ExperienceOperations: `Experience Operations`,
  FieldOperationsDirector: `Field Operations Director`,
  FieldPartner: `Field Partner`,
  FinancialAnalyst: `Financial Analyst`,
  Fundraising: `Fundraising`,
  Intern: `Intern`,
  LeadFinancialAnalyst: `Lead Financial Analyst`,
  Leadership: `Leadership`,
  Liaison: `Liaison`,
  Marketing: `Marketing`,
  Mentor: `Mentor`,
  ProjectManager: `Project Manager`,
  RegionalCommunicationsCoordinator: `Regional Communications Coordinator`,
  RegionalDirector: `Regional Director`,
  StaffMember: `Staff Member`,
  Translator: `Translator`,
};

export const ScheduleStatusList: readonly Types.ScheduleStatus[] = [
  'Behind',
  'OnTime',
  'Ahead',
];

export const ScheduleStatusLabels: Readonly<Record<Types.ScheduleStatus, string>> = {
  Ahead: `Ahead`,
  Behind: `Behind`,
  OnTime: `On Time`,
};

export const SearchTypeList: readonly Types.SearchType[] = [
  'DerivativeScriptureProduct',
  'DirectScriptureProduct',
  'EthnoArt',
  'FieldRegion',
  'FieldZone',
  'Film',
  'FinancialReport',
  'FundingAccount',
  'InternshipProject',
  'Language',
  'Location',
  'MomentumTranslationProject',
  'MultiplicationTranslationProject',
  'NarrativeReport',
  'Organization',
  'OtherProduct',
  'Partner',
  'PeriodicReport',
  'Product',
  'ProgressReport',
  'Project',
  'Story',
  'TranslationProject',
  'User',
];

export const SearchTypeLabels: Readonly<Record<Types.SearchType, string>> = {
  DerivativeScriptureProduct: `Derivative Scripture Product`,
  DirectScriptureProduct: `Direct Scripture Product`,
  EthnoArt: `Ethno Art`,
  FieldRegion: `Field Region`,
  FieldZone: `Field Zone`,
  Film: `Film`,
  FinancialReport: `Financial Report`,
  FundingAccount: `Funding Account`,
  InternshipProject: `Internship Project`,
  Language: `Language`,
  Location: `Location`,
  MomentumTranslationProject: `Momentum Translation Project`,
  MultiplicationTranslationProject: `Multiplication Translation Project`,
  NarrativeReport: `Narrative Report`,
  Organization: `Organization`,
  OtherProduct: `Other Product`,
  Partner: `Partner`,
  PeriodicReport: `Periodic Report`,
  Product: `Product`,
  ProgressReport: `Progress Report`,
  Project: `Project`,
  Story: `Story`,
  TranslationProject: `Translation Project`,
  User: `User`,
};

export const SensitivityList: readonly Types.Sensitivity[] = [
  'Low',
  'Medium',
  'High',
];

export const SensitivityLabels: Readonly<Record<Types.Sensitivity, string>> = {
  High: `High`,
  Low: `Low`,
  Medium: `Medium`,
};

export const TransitionTypeList: readonly Types.TransitionType[] = [
  'Approve',
  'Neutral',
  'Reject',
];

export const TransitionTypeLabels: Readonly<Record<Types.TransitionType, string>> = {
  Approve: `Approve`,
  Neutral: `Neutral`,
  Reject: `Reject`,
};

export const UserStatusList: readonly Types.UserStatus[] = [
  'Active',
  'Disabled',
];

export const UserStatusLabels: Readonly<Record<Types.UserStatus, string>> = {
  Active: `Active`,
  Disabled: `Disabled`,
};
